import { render, staticRenderFns } from "./LocationsPopover.vue?vue&type=template&id=2d9cedc6"
import script from "./LocationsPopover.vue?vue&type=script&lang=js"
export * from "./LocationsPopover.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiPopoverTrigger: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod/components/ui/UiPopoverTrigger.vue').default,UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod/components/ui/UiLink.vue').default,UiPopoverPanel: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod/components/ui/UiPopoverPanel.vue').default,UiPopover: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/ASBIS/ASBIS_Deploy_Prod/components/ui/UiPopover.vue').default})
