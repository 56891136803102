import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4e31bec6 = () => interopDefault(import('../pages/asbis-in-your-country.vue' /* webpackChunkName: "pages/asbis-in-your-country" */))
const _4a370fc3 = () => interopDefault(import('../pages/vendors/index.vue' /* webpackChunkName: "pages/vendors/index" */))
const _26dccda7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5dd238e9 = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _77de560b = () => interopDefault(import('../pages/documents/_slug/index.vue' /* webpackChunkName: "pages/documents/_slug/index" */))
const _33fb77c9 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _13fc796f = () => interopDefault(import('../pages/solution/_slug.vue' /* webpackChunkName: "pages/solution/_slug" */))
const _48806e7b = () => interopDefault(import('../pages/vendors/_slug.vue' /* webpackChunkName: "pages/vendors/_slug" */))
const _34f815e0 = () => interopDefault(import('../pages/where-to-buy/_slug.vue' /* webpackChunkName: "pages/where-to-buy/_slug" */))
const _25262c5f = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/asbis-in-your-country",
    component: _4e31bec6,
    name: "asbis-in-your-country___en"
  }, {
    path: "/vendors",
    component: _4a370fc3,
    name: "vendors___en"
  }, {
    path: "/",
    component: _26dccda7,
    name: "index___en"
  }, {
    path: "/catalog/:slug?",
    component: _5dd238e9,
    name: "catalog-slug___en"
  }, {
    path: "/documents/:slug",
    component: _77de560b,
    name: "documents-slug___en"
  }, {
    path: "/news/:slug?",
    component: _33fb77c9,
    name: "news-slug___en"
  }, {
    path: "/solution/:slug?",
    component: _13fc796f,
    name: "solution-slug___en"
  }, {
    path: "/vendors/:slug",
    component: _48806e7b,
    name: "vendors-slug___en"
  }, {
    path: "/where-to-buy/:slug?",
    component: _34f815e0,
    name: "where-to-buy-slug___en"
  }, {
    path: "/:slug",
    component: _25262c5f,
    name: "slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
